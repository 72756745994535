import cornerstone from "cornerstone-core";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import Hammer from "hammerjs";
import dicomParser from "dicom-parser";
import cornerstoneDICOMImageLoader from "cornerstone-wado-image-loader";

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.dicomParser = dicomParser;
cornerstone.registerImageLoader("http", cornerstoneWebImageLoader.loadImage);
cornerstone.registerImageLoader("https", cornerstoneWebImageLoader.loadImage);
cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;

cornerstoneTools.init([
  {
    moduleName: "globalConfiguration",
    configuration: {
      mouseEnabled: true,
      touchEnabled: true,
      globalToolSyncEnabled: true,
      showSVGCursors: true,
    },
  },
  {
    moduleName: "segmentation",
    configuration: {
      outlineWidth: 2,
    },
  },
]);

export { cornerstone, cornerstoneTools };
