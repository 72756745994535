<template>
  <b-card title="PACS Viewer">
    <b-form-radio-group @change="menuChange" button-variant="outline-secondary" v-model="selected" :options="options" class="mb-1" value-field="value" text-field="text" buttons />
    <hr />
    <div oncontextmenu="return false" unselectable="on" onselectstart="return false;" onmousedown="return false;" class="image-canvas-wrapper">
      <div oncontextmenu="return false" class="image-canvas" ref="viewportElement"></div>
    </div>
  </b-card>
</template>

<script>
import { cornerstone, cornerstoneTools } from "./CornerStoneHelper";

export default {
  data() {
    return {
      selected: "wwc",
      options: [
        { text: "Seviyeler", value: "wwc" },
        { text: "Yakınlaştır", value: "zoom" },
        { text: "Taşı", value: "pan" },
        { text: "ROI Penceresi", value: "roi" },
        { text: "Uzunluk", value: "length" },
        { text: "Sıfırla", value: "Reset" },
      ],
    };
  },
  mounted() {
    const element = this.$refs.viewportElement;

    cornerstone.enable(element);

    cornerstone.loadImage("wadouri:/denemedicom2.dcm").then(function (image) {
      const viewport = cornerstone.getDefaultViewportForImage(element, image);
      cornerstone.displayImage(element, image, viewport);

      cornerstoneTools.addTool(cornerstoneTools.ZoomTool);
      cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
      cornerstoneTools.addTool(cornerstoneTools.PanTool);
      cornerstoneTools.addTool(cornerstoneTools.LengthTool);
      cornerstoneTools.addTool(cornerstoneTools.RectangleRoiTool);

      cornerstoneTools.setToolActive("Wwwc", {
        mouseButtonMask: 1,
      });

      cornerstoneTools.setToolActive("Zoom", {
        mouseButtonMask: 2,
      });

      cornerstoneTools.setToolActive("Pan", {
        mouseButtonMask: 4,
      });
    });
  },
  methods: {
    menuChange(v) {
      const funcs = {
        wwc() {
          cornerstoneTools.setToolActive("Wwwc", {
            mouseButtonMask: 1,
          });
        },
        length() {
          cornerstoneTools.setToolActive("Length", {
            mouseButtonMask: 1,
          });
        },
        roi() {
          cornerstoneTools.setToolActive("RectangleRoi", {
            mouseButtonMask: 1,
          });
        },
        zoom() {
          cornerstoneTools.setToolActive("Zoom", {
            mouseButtonMask: 1,
          });
        },
        pan() {
          cornerstoneTools.setToolActive("Pan", {
            mouseButtonMask: 1,
          });
        },
      };
      funcs[v] && funcs[v]();
    },
  },
};
</script>

<style scoped>
.image-canvas-wrapper {
  width: 100%;
  height: 525px;
}

.image-canvas {
  width: 100%;
  height: 100%;
}
</style>
